import "./libs.bundle.js";
import "./jquery-bootstrap";
import "./froggy/class.js";
import "./froggy/polyfill.js";
import "./lib/moment.js";
import "./froggy/provide.js";
import "./froggy/translate.js";
import "./froggy/form/formatters.js";
import "./froggy/format.js";
import "./froggy/utils/number_format.js";
import "./froggy/utils/utils.js";
import "./froggy/utils/common.js";
import "./froggy/ui/messages.js";
import "./froggy/utils/pretty_date.js";
import "./froggy/utils/datetime.js";
import "./froggy/microtemplate.js";
import "./froggy/query_string.js";

import "./froggy/upload.js";

import "./froggy/model/meta.js";
import "./froggy/model/model.js";
import "./froggy/model/perm.js";

import "./bootstrap-4-web.bundle";

import "../../website/js/website.js";
import "./froggy/socketio_client.js";
